var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column justify-center align-center fill-height"
    },
    [
      _c(
        "v-card",
        { attrs: { "max-width": "600" } },
        [
          _c(
            "v-card-title",
            [
              _c("v-icon", { staticClass: "mr-1" }, [_vm._v("account_circle")]),
              _vm._v("Nuvolos account details")
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "black--text" },
            [
              _vm._v(
                " This is the first time you are accessing Nuvolos. Please provide some details to create a Nuvolos account associated with your university login: "
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "mt-3",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      counter: 50,
                      label: "First Name",
                      rules: [_vm.rules.nonEmpty],
                      required: ""
                    },
                    model: {
                      value: _vm.firstName,
                      callback: function($$v) {
                        _vm.firstName = $$v
                      },
                      expression: "firstName"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      counter: 50,
                      label: "Last Name",
                      rules: [_vm.rules.nonEmpty],
                      required: ""
                    },
                    model: {
                      value: _vm.lastName,
                      callback: function($$v) {
                        _vm.lastName = $$v
                      },
                      expression: "lastName"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: { label: "E-mail", required: "", readonly: "" },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        disabled: !_vm.valid,
                        loading: _vm.creatingAccount
                      },
                      on: { click: _vm.createAccount }
                    },
                    [
                      _c("v-icon", [_vm._v("navigate_next")]),
                      _vm._v("Create Linked Account ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "mt-4",
          attrs: { text: "", color: "secondary", small: "" },
          on: { click: _vm.showIntercom }
        },
        [
          _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
            _vm._v("support")
          ]),
          _vm._v("Ask for help")
        ],
        1
      ),
      _c("TheInvitationModal", {
        attrs: { message: "Creating account", show: _vm.creatingAccount }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }